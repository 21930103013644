export const setAuthToken = (token: string) =>
  localStorage.setItem('token', token);

export const getAuthToken = () => localStorage.getItem('token');

export const deleteAuthToken = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('has_completed_onboarding');
  if (window.location.pathname !== '/login') {
    window.location.href = '/login';
  }
};
