import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse
} from 'axios';
import { deleteAuthToken, getAuthToken, setAuthToken } from '../utils/auth';

const refreshToken = async () => {
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_SERVER_URL,
    withCredentials: true
  });
  const response = await axiosInstance.post('/auth/token/refresh/');
  const { data } = response;
  setAuthToken(data.access);
  return data.access;
};

const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
  const configuration = config;
  const token: any = getAuthToken();
  if (token && configuration.headers) {
    // eslint-disable-next-line @typescript-eslint/dot-notation
    configuration.headers.Authorization = `Bearer ${token}`;
  }
  return configuration;
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  return Promise.reject(error);
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
  return response;
};

const onResponseError = async (error: AxiosError): Promise<AxiosError> => {
  if (
    error.response &&
    error.config &&
    error.config.headers &&
    error.response.status === 401
  ) {
    try {
      const accessToken = await refreshToken();
      // eslint-disable-next-line no-param-reassign
      error.config.headers.Authorization = `Bearer ${accessToken}`;
      return axios.request(error.config);
    } catch (_error) {
      deleteAuthToken();
      return Promise.reject(_error);
    }
  }
  return Promise.reject(error);
};

export const setupInterceptorsTo = (
  axiosInstance: AxiosInstance
): AxiosInstance => {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);
  return axiosInstance;
};
