import { ThemeOptions } from '@mui/material';
import type { FC, ReactNode } from 'react';
import { createContext, useEffect, useState } from 'react';

enum THEMES {
  LIGHT = 'light',
  DARK = 'dark'
}

enum COLORS {
  PRIMARY = '#398ccb',
  SECONDARY = '#ea504c'
}

interface Settings {
  direction?: 'ltr' | 'rtl';
  responsiveFontSizes?: boolean;
  mode: THEMES;
  customThemeOptions?: ThemeOptions;
}

const FONT_FAMILY = {
  fontFamily: ['"Signika"', '-apple-system', 'sans-serif'].join(',')
};

const DEFAULT_CUSTOM_THEME_OPTIONS = {
  palette: {
    primary: {
      main: COLORS.PRIMARY
    },
    secondary: {
      main: COLORS.SECONDARY
    }
  },
  typography: {
    body1: FONT_FAMILY,
    body2: FONT_FAMILY,
    button: FONT_FAMILY,
    caption: FONT_FAMILY,
    h1: FONT_FAMILY,
    h2: FONT_FAMILY,
    h3: FONT_FAMILY,
    h4: FONT_FAMILY,
    h5: FONT_FAMILY,
    h6: FONT_FAMILY,
    overline: FONT_FAMILY,
    subtitle1: FONT_FAMILY,
    subtitle2: FONT_FAMILY
  }
};

export interface SettingsContextValue {
  settings: Settings;
  saveSettings: (update: Settings) => void;
}

interface SettingsProviderProps {
  children?: ReactNode;
}

const initialSettings: Settings = {
  direction: 'ltr',
  responsiveFontSizes: true,
  mode: THEMES.LIGHT,
  customThemeOptions: DEFAULT_CUSTOM_THEME_OPTIONS
};

export const restoreSettings = (): Settings | null => {
  let settings: Settings | null = null;

  try {
    const storedData: string | null = window.localStorage.getItem('settings');
    if (storedData) {
      settings = JSON.parse(storedData);
    } else {
      settings = initialSettings;
    }
  } catch (err) {
    // If stored data is not a strigified JSON this will fail,
    // that's why we catch the error
  }
  return settings;
};

export const storeSettings = (settings: Settings): void => {
  window.localStorage.setItem('settings', JSON.stringify(settings));
};

export const SettingsContext = createContext<SettingsContextValue>({
  settings: initialSettings,
  saveSettings: () => {
    // empty
  }
});

export const SettingsProvider: FC<SettingsProviderProps> = (props) => {
  const { children } = props;
  const [settings, setSettings] = useState<Settings>(initialSettings);

  useEffect(() => {
    const restoredSettings = restoreSettings();

    if (restoredSettings) {
      setSettings(restoredSettings);
    }
  }, []);

  const saveSettings = (updatedSettings: Settings): void => {
    setSettings(updatedSettings);
    storeSettings(updatedSettings);
  };

  return (
    <SettingsContext.Provider
      value={{
        settings,
        saveSettings
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export const SettingsConsumer = SettingsContext.Consumer;
