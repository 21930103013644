import dayjs from 'dayjs';
import jwtDecode from 'jwt-decode';
import { getAuthToken } from './auth';

export const openFileFromUrl = async (url?: string) => {
  if (url) {
    const link = document.createElement('a');
    link.href = url;

    // Set attributes to link
    link.setAttribute('target', '_blank');
    link.click();
  }
};

export const addFileToFormData = (
  formData: FormData,
  file: any,
  key: string
) => {
  if (file) {
    formData.append(key, file);
  }
};

export const getErrorMessage = (error: any, defaultMessage: string, t: any) => {
  let errorMessage = '';
  if (error.response.status === 400) {
    const errorData = error.response.data;
    Object.entries<any>(errorData).forEach(([key, value]) => {
      errorMessage += `${key}: ${value}\n`;
    });
  } else if (error.response.status === 404) {
    errorMessage = t('error.notFound');
  } else if (error.response.status === 403) {
    errorMessage = t('error.forbidden');
  }
  return errorMessage || defaultMessage;
};

export const getDecodeToken = () => {
  const token = getAuthToken();
  if (token) {
    return jwtDecode<any>(token);
  }
  return null;
};

export const checkRoles = (allowedRoles?: Role[]) => {
  const decodeToken = getDecodeToken();
  const groups = decodeToken?.groups
    ? decodeToken.groups?.map((group: any) => group.name)
    : null;
  if (
    allowedRoles &&
    allowedRoles.length > 0 &&
    groups &&
    groups.length > 0 &&
    decodeToken
  ) {
    return allowedRoles.some((role) => groups.includes(role.toLowerCase()));
  }
  return false;
};

export const isEmptyObject = (obj: any) => obj && Object.keys(obj).length !== 0;

export const processDateTime = (value: any) =>
  dayjs(value).format('YYYY-MM-DD HH:mm:ss');

export const processBoolean = (value: boolean, t: any) =>
  value ? t('boolean.yes') : t('boolean.no');

export const processPercentage = (value: number) => `${value}%`;

export const isTokenValid = () => {
  const decodeToken = getDecodeToken();
  if (!decodeToken) return false;
  if (decodeToken.exp > Date.now()) return false;
  return true;
};

export const handleNullValue = (obj: any, value: any) => {
  if (obj !== null) {
    return value;
  }
  return '-';
};
