import { useUserContext } from 'hooks/useUserContext';
import { FC, Fragment, lazy, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import AuthGuard from 'shared/components/Auth/AuthGuard';
import GuestAuthGuard from 'shared/components/Auth/GuestAuthGuard';
import { Layout as MainLayout } from './layouts/Layout';
import { GuestGuard, LoadingScreen, RolesGuard } from './shared/components';

type RoutesType = {
  exact?: boolean;
  path?: string;
  guard?: FC;
  layout?: FC;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  component?: any;
  routes?: RoutesType[];
};

const routesConfig: RoutesType[] = [
  {
    exact: true,
    guard: AuthGuard,
    path: '/',
    component: () => <Navigate to="/dashboard" />
  },
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('./views/pages/NotFound'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('./views/pages/Authentication/Login'))
  },
  {
    exact: true,
    guard: GuestAuthGuard,
    path: '/logout',
    component: lazy(() => import('./views/pages/Authentication/Logout/index'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/signup',
    component: lazy(() => import('./views/pages/Authentication/UserSignup'))
  },
  {
    exact: true,
    path: '/forgot-password',
    component: lazy(() => import('./views/pages/Authentication/ForgotPassword'))
  },
  {
    exact: true,
    path: '/reset-password/:token',
    component: lazy(() => import('./views/pages/Authentication/ResetPassword'))
  },
  {
    path: '/profile/*',
    guard: AuthGuard,
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/:id/edit',
        component: lazy(() => import('./views/pages/Profiles/Form/ProfileForm'))
      },
      {
        exact: true,
        path: '/:tab',
        component: lazy(
          () => import('./views/pages/Profiles/Details/ProfileDetails')
        )
      }
    ]
  },
  {
    path: '/users/*',
    guard: RolesGuard(['ADMIN']),
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/',
        component: lazy(() => import('./views/pages/Users/UserList'))
      },
      {
        exact: true,
        path: '/create',
        component: lazy(() => import('./views/pages/Users/Form/UserForm'))
      },
      {
        exact: true,
        path: '/:id/edit',
        component: lazy(() => import('./views/pages/Users/Form/UserForm'))
      },
      {
        exact: true,
        path: '/:id/:tab',
        component: lazy(() => import('./views/pages/Users/Details/UserDetails'))
      }
    ]
  },
  {
    path: '/mentors/*',
    guard: RolesGuard(['ADMIN']),
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/',
        component: lazy(() => import('./views/pages/Mentors/MentorList'))
      },
      {
        exact: true,
        path: '/create',
        component: lazy(() => import('./views/pages/Mentors/Form/MentorForm'))
      },
      {
        exact: true,
        path: '/:id/edit',
        component: lazy(() => import('./views/pages/Mentors/Form/MentorForm'))
      },
      {
        exact: true,
        path: '/:id/:tab',
        component: lazy(
          () => import('./views/pages/Mentors/Details/MentorDetails')
        )
      }
    ]
  },
  {
    path: '/requests/*',
    guard: RolesGuard(['ADMIN']),
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/',
        component: lazy(() => import('./views/pages/Requests/RequestList'))
      },
      {
        exact: true,
        path: '/create',
        component: lazy(() => import('./views/pages/Requests/Form/RequestForm'))
      },
      {
        exact: true,
        path: '/:id/edit',
        component: lazy(() => import('./views/pages/Requests/Form/RequestForm'))
      },
      {
        exact: true,
        path: '/:id/:tab',
        component: lazy(
          () => import('./views/pages/Requests/Details/RequestDetails')
        )
      }
    ]
  },
  {
    path: '/courses/*',
    guard: RolesGuard(['ADMIN']),
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/',
        component: lazy(() => import('./views/pages/Courses/CourseList'))
      },
      {
        exact: true,
        path: '/create',
        component: lazy(() => import('./views/pages/Courses/Form/CourseForm'))
      },
      {
        exact: true,
        path: '/:id/edit',
        component: lazy(() => import('./views/pages/Courses/Form/CourseForm'))
      },
      {
        exact: true,
        path: '/:id/:tab',
        component: lazy(
          () => import('./views/pages/Courses/Details/CourseDetails')
        )
      },
      {
        exact: true,
        path: ':courseId/sessions/create',
        component: lazy(
          () => import('./views/pages/Courses/Details/Sessions/CourseSession')
        )
      },
      {
        exact: true,
        path: ':courseId/sessions/:id/edit',
        component: lazy(
          () => import('./views/pages/Courses/Details/Sessions/CourseSession')
        )
      }
    ]
  },
  {
    path: '/payment-packages/*',
    guard: RolesGuard(['ADMIN']),
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/',
        component: lazy(
          () => import('./views/pages/PaymentPackages/PaymentPackageList')
        )
      },
      {
        exact: true,
        path: '/create',
        component: lazy(
          () => import('./views/pages/PaymentPackages/Form/PaymentPackageForm')
        )
      },
      {
        exact: true,
        path: '/:id/edit',
        component: lazy(
          () => import('./views/pages/PaymentPackages/Form/PaymentPackageForm')
        )
      },
      {
        exact: true,
        path: '/:id/:tab',
        component: lazy(
          () =>
            import(
              './views/pages/PaymentPackages/Details/PaymentPackageDetails'
            )
        )
      },
      {
        exact: true,
        path: ':paymentPackageId/benefits/create',
        component: lazy(
          () =>
            import(
              './views/pages/PaymentPackages/Details/Benefits/PaymentPackagesBenefit'
            )
        )
      },
      {
        exact: true,
        path: ':paymentPackageId/benefits/:id/edit',
        component: lazy(
          () =>
            import(
              './views/pages/PaymentPackages/Details/Benefits/PaymentPackagesBenefit'
            )
        )
      }
    ]
  },
  {
    exact: true,
    path: '/dashboard',
    guard: AuthGuard,
    layout: MainLayout,
    component: lazy(() => import('./views/pages/Dashboard'))
  },
  {
    path: '*',
    component: () => <Navigate to="/404" />
  }
];

const renderRoutes = (routes: RoutesType[]) =>
  routes ? (
    <Suspense fallback={<LoadingScreen />}>
      <Routes>
        {routes.map((route, i) => {
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;
          const Component = route.component;
          return (
            <Route
              key={i}
              path={route.path ?? ''}
              element={
                <Guard>
                  <Layout>
                    {route.routes ? renderRoutes(route.routes) : <Component />}
                  </Layout>
                </Guard>
              }
            />
          );
        })}
      </Routes>
    </Suspense>
  ) : null;

const AppRoutes = () => {
  useUserContext();
  return renderRoutes(routesConfig);
};

export default AppRoutes;
