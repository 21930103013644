import { FC } from 'react';
import { Navigate } from 'react-router-dom';
import { checkRoles } from 'shared/utils/functions';

type Props = (allowedRoles?: Role[]) => FC;

const RolesGuard: Props =
  (allowedRoles) =>
  ({ children }) => {
    const isAllowed = checkRoles(allowedRoles);
    if (isAllowed) return <>{children}</>;

    return <Navigate to="/login" />;
  };

export default RolesGuard;
