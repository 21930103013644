import { useUser } from 'contexts/UserContext';
import { FC } from 'react';
import { Navigate } from 'react-router-dom';
import { getAuthToken } from 'shared/utils/auth';

const GuestGuard: FC = ({ children }) => {
  const token = getAuthToken();
  const context = useUser();
  if (token && context?.currentUser) {
    return <Navigate to={'/'} />;
  }

  return <>{children}</>;
};

export default GuestGuard;
