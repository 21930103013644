import { createTheme } from '@inappsis/react-inappsis-ui';
import { LocalizationProvider } from '@mui/lab';
import AdapterDayjs from '@mui/lab/AdapterDayjs';
import { ThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { SnackbarProvider } from 'contexts/SnackbarContext';
import es from 'dayjs/locale/es';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter } from 'react-router-dom';
import { UserProvider } from './contexts/UserContext';
import { useSettings } from './hooks/useSettings';
import './i18n';
import AppRoutes from './Routes';

export const queryClient = new QueryClient();

function App() {
  const { settings } = useSettings();
  const theme = createTheme(settings);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} locale={es}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider>
          <BrowserRouter>
            <UserProvider>
              <QueryClientProvider client={queryClient}>
                <CssBaseline />
                <AppRoutes />
                <ReactQueryDevtools initialIsOpen={false} />
              </QueryClientProvider>
            </UserProvider>
          </BrowserRouter>
        </SnackbarProvider>
      </ThemeProvider>
    </LocalizationProvider>
  );
}

export default App;
