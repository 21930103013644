import { useUser } from 'contexts/UserContext';
import { useEffect } from 'react';
import { getDecodeToken } from 'shared/utils/functions';
import useProfileDetails from 'views/pages/Profiles/hooks/useProfileDetails';

/**
 * Hook to set the user context when the user is already logged in
 */
export const useUserContext = () => {
  const user = useUser();
  const decodedToken = getDecodeToken();
  const { data: userData } = useProfileDetails(
    decodedToken ? decodedToken.user_id : undefined
  );

  useEffect(() => {
    if (userData) {
      const currentUser: AuthUser = {
        user_id: userData.id,
        first_name: userData.first_name,
        last_name: userData.last_name,
        email: userData.email,
        photo: userData.photo,
        groups: userData.groups?.map((group: any) => group.name),
        has_completed_onboarding: decodedToken.has_completed_onboarding
      };
      user?.setCurrentUser(currentUser);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);
};
