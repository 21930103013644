import { styled } from '@mui/material/styles';

interface LogoProps {
  variant?: 'light' | 'primary';
}

export const Logo = styled((props: LogoProps) => {
  const { variant, ...other } = props;

  return <img {...other} src={`${process.env.PUBLIC_URL}/logo.png`} />;
})``;
