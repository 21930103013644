import { VARIANTS } from 'contexts/SnackbarContext';
import useSnackbars from 'hooks/useSnackbar';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import api from 'shared/services/api';
import { PROFILE_CACHE_KEYS } from '../Profile.constants';

const getProfileRequest = async (userId?: number): Promise<SimpleUser> => {
  const { data } = await api.get(`/auth/users/${userId}`);
  return data;
};

const useProfileDetails = (userId?: number) => {
  const { t } = useTranslation();
  const { addSnackbar } = useSnackbars();
  return useQuery<SimpleUser>(
    [PROFILE_CACHE_KEYS.profile, userId],
    async () => getProfileRequest(userId),
    {
      staleTime: Infinity,
      notifyOnChangeProps: ['data', 'error'],
      enabled: !!userId,
      onError: () => {
        addSnackbar(
          VARIANTS.error,
          t('profilePage.profileCRUDError', {
            action: t('CRUDGerund.retrieving')
          })
        );
      }
    }
  );
};

export default useProfileDetails;
