import { UserCircle as UserCircleIcon } from '@inappsis/react-inappsis-ui';
import { Avatar, Box, ButtonBase } from '@mui/material';
import { useUser } from 'contexts/UserContext';
import { useRef, useState } from 'react';
import { DEFAULT_AVATAR } from 'shared/constant/Common.constants';
import { AccountPopover } from './AccountPopover';

export const AccountButton = () => {
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const [openPopover, setOpenPopover] = useState<boolean>(false);
  const user = useUser();

  const handleOpenPopover = (): void => {
    setOpenPopover(true);
  };

  const handleClosePopover = (): void => {
    setOpenPopover(false);
  };

  return (
    <>
      <Box
        component={ButtonBase}
        onClick={handleOpenPopover}
        ref={anchorRef}
        sx={{
          alignItems: 'center',
          display: 'flex',
          ml: 2
        }}
      >
        <Avatar
          sx={{
            height: 40,
            width: 40
          }}
          src={
            user?.currentUser?.photo ? user.currentUser.photo : DEFAULT_AVATAR
          }
        >
          <UserCircleIcon fontSize="small" />
        </Avatar>
      </Box>
      <AccountPopover
        anchorEl={anchorRef.current}
        onClose={handleClosePopover}
        open={openPopover}
        currentUser={user?.currentUser}
      />
    </>
  );
};
