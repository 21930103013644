import { createContext, Dispatch, useContext, useState } from 'react';

type UserContextInterface = {
  currentUser: AuthUser;
  setCurrentUser: Dispatch<any>;
};
const UserContext = createContext<UserContextInterface | null>(null);

export const UserProvider = ({ user, children }: any): any => {
  const [currentUser, setCurrentUser] = useState(user);

  return (
    <UserContext.Provider value={{ currentUser, setCurrentUser }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
