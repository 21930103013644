import { useUser } from 'contexts/UserContext';
import { deleteAuthToken } from 'shared/utils/auth';

const useLogout = () => {
  const user = useUser();

  function logout() {
    deleteAuthToken();
    user?.setCurrentUser(null);
  }

  return logout;
};

export default useLogout;
