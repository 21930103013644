import {
  Home as HomeIcon,
  Item,
  UserCircle as UserCircleIcon,
  Users as UsersIcon
} from '@inappsis/react-inappsis-ui';
import { Event as EventIcon } from '@mui/icons-material';
import { Chip, Typography } from '@mui/material';
import type { TFunction } from 'react-i18next';

export const dashboardItem = (t: TFunction): Item => ({
  title: t('layout.generalSection.dashboard'),
  path: '/dashboard',
  icon: <HomeIcon fontSize="small" />
});

export const profileItem = (t: TFunction): Item => ({
  title: t('layout.generalSection.profile'),
  path: '/profile/details',
  icon: <UserCircleIcon fontSize="small" />
});

export const usersItem = (t: TFunction): Item => ({
  title: t('layout.managementSection.users'),
  path: '/users',
  icon: <UsersIcon fontSize="small" />,
  chip: (
    <Chip
      color="secondary"
      label={
        <Typography
          sx={{
            fontSize: '10px',
            fontWeight: '600'
          }}
        >
          NEW
        </Typography>
      }
      size="small"
    />
  )
});

export const mentorsItem = (t: TFunction): Item => ({
  title: t('layout.managementSection.mentors'),
  path: '/mentors',
  icon: <UsersIcon fontSize="small" />
});

export const requestsItem = (t: TFunction): Item => ({
  title: t('layout.managementSection.requests'),
  path: '/requests',
  icon: <UsersIcon fontSize="small" />
});

export const coursesItem = (t: TFunction): Item => ({
  title: t('layout.managementSection.courses'),
  path: '/courses',
  icon: <UsersIcon fontSize="small" />
});

export const paymentPackagesItem = (t: TFunction): Item => ({
  title: t('layout.managementSection.paymentpackages'),
  path: '/payment-packages',
  icon: <UsersIcon fontSize="small" />
});

export const sessionsItem = (t: TFunction): Item => ({
  title: t('layout.userSection.sessions'),
  path: '/sessions',
  icon: <EventIcon fontSize="small" />
});
