import axios from 'axios';
import { setupInterceptorsTo } from './interceptor';

const api = setupInterceptorsTo(
  axios.create({
    baseURL: process.env.REACT_APP_API_SERVER_URL,
    withCredentials: true
  })
);

export default api;
