import { Section } from '@inappsis/react-inappsis-ui';
import { TFunction } from 'i18next';
import {
  coursesItem,
  dashboardItem,
  mentorsItem,
  paymentPackagesItem,
  profileItem,
  requestsItem,
  sessionsItem,
  usersItem
} from './LayoutItems';

export const generalSections = (t: TFunction): Section => {
  return {
    title: t('layout.sections.general'),
    items: [dashboardItem(t), profileItem(t)]
  };
};

export const adminSection = (t: TFunction): Section => ({
  title: t('layout.sections.management'),
  items: [
    usersItem(t),
    mentorsItem(t),
    requestsItem(t),
    coursesItem(t),
    paymentPackagesItem(t)
  ]
});

export const userSection = (t: TFunction): Section => ({
  title: t('layout.sections.mentoring'),
  items: [sessionsItem(t), coursesItem(t)]
});
