import { Alert, Snackbar } from '@mui/material';
import { createContext, FC, ReactNode, useCallback, useState } from 'react';

export enum VARIANTS {
  success = 'success',
  error = 'error',
  warning = 'warning',
  info = 'info'
}

type ProviderProps = {
  children: ReactNode;
};

interface SnackbarContextType {
  addSnackbar: (variant: string, message: string) => void;
}

export const SnackbarContext = createContext<SnackbarContextType>(
  {} as SnackbarContextType
);

export const SnackbarProvider: FC<ProviderProps> = ({ children }) => {
  const [alert, setAlert] = useState<any>(undefined);
  const [open, setOpen] = useState<boolean>(false);

  const addSnackbar = useCallback((variant: string, message: string) => {
    setAlert({
      severity: variant,
      message
    });
    setOpen(true);
  }, []);

  const handleClose = (
    event: Event | React.SyntheticEvent<any, Event>,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
    setAlert(undefined);
  };
  return (
    <SnackbarContext.Provider value={{ addSnackbar }}>
      {children}
      {open && alert && (
        <Snackbar
          key={new Date().getTime()}
          open={open}
          onClose={handleClose}
          autoHideDuration={5000}
        >
          <Alert severity={alert.severity}>{alert.message}</Alert>
        </Snackbar>
      )}
    </SnackbarContext.Provider>
  );
};
