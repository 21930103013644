import { FC } from 'react';
import { Navigate } from 'react-router-dom';
import { isTokenValid } from 'shared/utils/functions';

const AuthGuard: FC = ({ children }) => {
  if (!isTokenValid()) {
    return <Navigate to={'/login'} />;
  }
  return <>{children}</>;
};

export default AuthGuard;
